import React from 'react'

import './twitter1.css'

export default function Twitter1() {
  return (
    <div className="twitter1-twitter1">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.9162 8.88969C26.9353 9.15617 26.9353 9.42271 26.9353 9.6892C26.9353 17.8173 20.7487 27.1828 9.44162 27.1828C5.95811 27.1828 2.72209 26.1739 0 24.4227C0.494941 24.4798 0.970781 24.4988 1.48477 24.4988C4.35908 24.4988 7.00506 23.528 9.11801 21.872C6.41496 21.8148 4.14973 20.0445 3.36926 17.608C3.75 17.665 4.13068 17.7031 4.53047 17.7031C5.08248 17.7031 5.63455 17.627 6.14848 17.4938C3.33123 16.9227 1.21822 14.4481 1.21822 11.4595V11.3834C2.03672 11.8402 2.98857 12.1258 3.99738 12.1638C2.34129 11.0597 1.25631 9.17521 1.25631 7.04322C1.25631 5.90111 1.56082 4.85416 2.09385 3.94045C5.12051 7.67141 9.67002 10.1079 14.7715 10.3745C14.6763 9.9176 14.6192 9.44176 14.6192 8.96586C14.6192 5.5775 17.3603 2.81738 20.7677 2.81738C22.538 2.81738 24.1369 3.55977 25.2601 4.759C26.6496 4.49252 27.9821 3.97854 29.1623 3.27424C28.7054 4.70193 27.7346 5.90117 26.4593 6.66254C27.6966 6.52936 28.8959 6.18664 29.9999 5.7108C29.1625 6.92902 28.1154 8.014 26.9162 8.88969V8.88969Z"
          fill="#49C3F8"
        />
      </svg>
    </div>
  )
}
