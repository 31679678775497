import React from 'react'

import './eye-solid1.css'

export default function EyeSolid1() {
  return (
    <div className="eye-solid1-eye-solid1">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 40 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_20_21)">
          <path
            d="M19.4167 11.1165C19.6111 11.0957 19.8056 11.0887 20 11.0887C23.6806 11.0887 26.6667 14.0065 26.6667 17.742C26.6667 21.4151 23.6806 24.3952 20 24.3952C16.2569 24.3952 13.3333 21.4151 13.3333 17.742C13.3333 17.5479 13.3403 17.3539 13.3611 17.1598C14.0069 17.4717 14.7569 17.742 15.5556 17.742C18.0069 17.742 20 15.7529 20 13.3065C20 12.5095 19.7292 11.761 19.4167 11.1165ZM33.375 7.80371C36.625 10.8115 38.7986 14.353 39.8264 16.8895C40.0556 17.437 40.0556 18.0469 39.8264 18.5944C38.7986 21.0686 36.625 24.61 33.375 27.6802C30.1042 30.7158 25.6111 33.2662 20 33.2662C14.3889 33.2662 9.89583 30.7158 6.62639 27.6802C3.37639 24.61 1.20417 21.0686 0.170904 18.5944C-0.0569781 18.0469 -0.0569781 17.437 0.170904 16.8895C1.20417 14.353 3.37639 10.8115 6.62639 7.80371C9.89583 4.77095 14.3889 2.21777 20 2.21777C25.6111 2.21777 30.1042 4.77095 33.375 7.80371ZM20 7.76213C14.4792 7.76213 10 12.2323 10 17.742C10 23.2517 14.4792 27.7218 20 27.7218C25.5208 27.7218 30 23.2517 30 17.742C30 12.2323 25.5208 7.76213 20 7.76213Z"
            fill="#F8F1E3"
          />
        </g>
        <defs>
          <clipPath id="clip0_20_21">
            <rect width="40" height="35.4839" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
