import React from 'react'

import Vector1 from './assets/vector1'
import Twitter1 from './assets/twitter1'
import Vector from './assets/vector'
import Vector2 from './assets/vector2'
import Vector3 from './assets/vector3'
import Vector4 from './assets/vector4'
import CircleCheckSolid1 from './assets/circle-check-solid1'
import StampSolid1 from './assets/stamp-solid1'
import EyeSolid1 from './assets/eye-solid1'
import './stamp-protocol-home.css'

export default function StampProtocolHome() {
  return (
    <div className="stamp-protocol-home-container">
      <div className="stamp-protocol-home-stamp-protocol-home">
        <div className="stamp-protocol-home-unsplash-qusxjo_d4lu" />
        <div className="stamp-protocol-home-rectangle1" />
        <div className="stamp-protocol-home-footer">
          <div className="stamp-protocol-home-rectangle8" />
          <a href="https://permapages.arweave.dev/"><p className="stamp-protocol-home-integrate-stamps">
            Integrate Stamps
          </p></a>
          <a href="https://www.onlyarweave.com/stamp"><p className="stamp-protocol-home-begin-stamping">Begin Stamping</p></a>
          <a href="https://www.vouchdao.xyz/"><p className="stamp-protocol-home-get-vouched">Get Vouched</p></a>
          <p className="stamp-protocol-home-information">Information</p>
          <p className="stamp-protocol-home-creating-a-new-way-t">
            Creating a new way to engage with content on the permaweb! Applaud
            good content via your stamps and creators are rewarded.
          </p>
          <p className="stamp-protocol-home-stamp-protocol">Stamp Protocol</p>
          <p className="stamp-protocol-home-community">Community</p>
          <p className="stamp-protocol-home-stamp-protocol-by-pe">
            <span className="stamp-protocol-home-stamp-protocol-by-text0">
              Stamp Protocol by&#160;
            </span>
            <span className="stamp-protocol-home-perma-pages-text1">
              PermaPages
            </span>
          </p>
          <a href="https://twitter.com/permapages"><Vector1 /></a>
          <a href="https://arweave.org"><div className="stamp-protocol-home-permement-on-arweave1" /></a>
          <a href="https://twitter.com/permapages"><Twitter1 /></a>
          <a href="https://discord.gg/jRTnwdrxg5"><Vector /></a>
        </div>
        <div className="stamp-protocol-home-container1">
          <div className="stamp-protocol-home-frame2">
            <div className="stamp-protocol-home-stamps1" />
            <p className="stamp-protocol-home-how-does-stamp-proto">
              How Does Stamp Protocol Work?
            </p>
            <div className="stamp-protocol-home-stamp-protocol-allow">
              <p className="stamp-protocol-home-stamp-protocol-allow-text0">
                Stamp Protocol allows users to stamp content they come across on
                the&#160;
              </p>
              <a href="https://arweave.medium.com/welcome-to-the-permaweb-ce0e6c73ddfb"><p className="stamp-protocol-home-permaweb-text1">permaweb</p></a>
              <p className="stamp-protocol-home-when-a-user-stamp-text2">
                . When a user “stamps” content, the&#160;
              </p>
              <p className="stamp-protocol-home-content-creator-text3">
                content creator
              </p>
              <p className="stamp-protocol-home-receives-stampcoins-text4">
                &#160;receives StampCoins. The more stamps a specific piece
                content gets, the more StampCoins the creator collects!
                <br />
                <br />
                To ensure that this process isn’t botted, StampProtocol
                uses&#160;
              </p>
              <a href="https://www.vouchdao.xyz/"><p className="stamp-protocol-home-vouch-d-a-o-text5">VouchDAO</p></a>
              <p className="stamp-protocol-home-to-verify-users-hum-text6">
                &#160;to verify users humanity.
                <br />
                <br />
                Creators can distribute their rewards via a profit sharing
                token, atomically built into their media on&#160;
              </p>
              <a href="https://www.arweave.org/"><p className="stamp-protocol-home-arweave-text7">Arweave</p></a>
              <p className="stamp-protocol-home-this-creates-marke-text8">
                . This creates markets that anyone can take part in, for any
                content, in any app, on the permaweb.
              </p>
            </div>
          </div>
        </div>
        <div className="stamp-protocol-home-header-menu">
          <div>
            <a href="https://twitter.com/permapages"><Vector2 /></a>
            <a href="https://discord.gg/jRTnwdrxg5"><Vector3 /></a>
            <a href="https://gist.github.com/abhavk/b9c24cc2786437e377475951520c1cca"><Vector4 /></a>
            <a href="https://www.vouchdao.xyz/"><p className="stamp-protocol-home-get-vouched1">Get Vouched</p></a>
            <a href="https://www.onlyarweave.com/stamp"><p className="stamp-protocol-home-begin-stamping1">
              Begin Stamping
            </p></a>
            <a href="https://permapages.arweave.dev/"><p className="stamp-protocol-home-integrate-stamps1">
              Integrate Stamps
            </p></a>
            <p className="stamp-protocol-home-stamp-protocol1">
              Stamp Protocol
            </p>
            <div className="stamp-protocol-home-stamp1" />
          </div>
        </div>
        <div className="stamp-protocol-home-rectangle3" />
        <p className="stamp-protocol-home-a-world-without-ads">
          A World Without Ads
        </p>
        <div className="stamp-protocol-home-rectangle2" />
        <a href="https://www.onlyarweave.com/stamp"><p className="stamp-protocol-home-get-stamping">Get Stamping</p></a>
        <div className="stamp-protocol-home-rewards-for-content">
          <p className="stamp-protocol-home-rewards-for-content-text0">
            Rewards for content creators on the web are broken. Clickbait
            spreads faster than great content, while providing less value.
            Creators are pushed, in a race to the bottom, to produce attention
            grabbing, time-wasting content -- all in order to sell ads.
            <br />
            <br />
          </p>
          <p className="stamp-protocol-home-stamp-protocol-fixes-text1">
            Stamp Protocol fixes this.
          </p>
        </div>
        <div className="stamp-protocol-home-untitled2fade1" />
        <div className="stamp-protocol-home-ellipse1" />
        <div className="stamp-protocol-home-fluent-leaf-two16regular" />
        <p className="stamp-protocol-home-how-do-i-stamp">How Do I Stamp?</p>
        <p className="stamp-protocol-home-while-you-re-browsin">
          While you’re browsing the permaweb, we’ve made it very easy to stamp
          content you’re engaging with.
        </p>
        <div className="stamp-protocol-home-ellipse2" />
        <div className="stamp-protocol-home-ellipse3" />
        <a href="https://www.vouchdao.xyz/"><p className="stamp-protocol-home-get-vouched2">Get Vouched</p></a>
        <p className="stamp-protocol-home-get-a-vouchdao-verif">
          Get a VouchDAO verified address to Vouch&#160;
          <br />
          for your humanity!
        </p>
        <p className="stamp-protocol-home-browse-the-permaweb">
          Browse the Permaweb
        </p>
        <p className="stamp-protocol-home-look-for-the-stamp-p">
          Look for the Stamp Protocol icon embedded in pages on the permaweb.
          Stamp content you value!
        </p>
        <p className="stamp-protocol-home-stamp-content">Stamp Content!</p>
        <p className="stamp-protocol-home-connect-your-wallet">
          Connect your wallet and stamp content
          <br />
          to show the world that you like it!
        </p>
        <div className="stamp-protocol-home-unsplash-qusxjo_d4lu1" />
        <p className="stamp-protocol-home-stamp-protocol-is-on">
          Stamp Protocol is one of the first horizontal protocols on the web of
          value. Check out the design and implementation of StampCoin here.
        </p>
        <p className="stamp-protocol-home-want-to-look-under-t">
          Want to Look Under the Hood?
        </p>
        <div className="stamp-protocol-home-rectangle21" />
        <a href="https://gist.github.com/abhavk/b9c24cc2786437e377475951520c1cca"><p className="stamp-protocol-home-learn-more">Learn More</p></a>
        <div className="stamp-protocol-home-unsplash-qusxjo_d4lu2" />
        <p className="stamp-protocol-home-permapages-is-the-fi">
          Permapages is the first app to incoporate the Stamp Protocol. Visit
          the Permapage app to create your own page on the permaweb
          <br />
          and start gathering stamps.
        </p>
        <p className="stamp-protocol-home-add-stamps-to-your-p">
          Add Stamps to your Permapage
        </p>
        <div className="stamp-protocol-home-rectangle22" />
        <a href="https://permapages.arweave.dev/"><p className="stamp-protocol-home-visit-permapages">Visit PermaPages</p></a>
        <div className="stamp-protocol-home-first-to-stamp">
          <p className="stamp-protocol-home-good-content-will-be">
            Good content will be found by users like you! Help others navigate
            the permaweb by stamping content that you love.
            <br />
            <br />
            Are you a content creator? Create your own permapage to start
            getting stamps!
          </p>
          <div className="stamp-protocol-home-component" />
          <p className="stamp-protocol-home-be-the-first-to-stam">
            Be The First to Stamp Great Content!
          </p>
          <div className="stamp-protocol-home-rectangle16" />
          <div className="stamp-protocol-home-rectangle17" />
          <a href="https://permapages.arweave.dev/"><p className="stamp-protocol-home-create-a-page">Create a Page</p></a>
          <a href="https://www.onlyarweave.com/stamp"><p className="stamp-protocol-home-browse-pages">Browse Pages</p></a>
        </div>
        <CircleCheckSolid1 />
        <StampSolid1 />
        <EyeSolid1 />
        <div className="stamp-protocol-home-rectangle23" />
        <a href="https://www.vouchdao.xyz/"><p className="stamp-protocol-home-get-vouched3">Get Vouched</p></a>
        <div className="stamp-protocol-home-rectangle31" />
        <a href="https://www.onlyarweave.com/stamp"><p className="stamp-protocol-home-get-stamping1">Get Stamping</p></a>
      </div>
    </div>
  )
}
