import React from 'react'

import StampProtocolHome from '../components/stamp-protocol-home'

export default function Main() {
  return (
    <>
      <StampProtocolHome />
    </>
  )
}
