import React from 'react'

import './circle-check-solid1.css'

export default function CircleCheckSolid1() {
  return (
    <div className="circle-check-solid1-circle-check-solid1">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 20C0 8.95313 8.95313 0 20 0C31.0469 0 40 8.95313 40 20C40 31.0469 31.0469 40 20 40C8.95313 40 0 31.0469 0 20ZM29.0469 16.5469C29.8984 15.6953 29.8984 14.3047 29.0469 13.4531C28.1953 12.6016 26.8047 12.6016 25.9531 13.4531L17.5 21.9062L14.0469 18.4531C13.1953 17.6016 11.8047 17.6016 10.9531 18.4531C10.1016 19.3047 10.1016 20.6953 10.9531 21.5469L15.9531 26.5469C16.8047 27.3984 18.1953 27.3984 19.0469 26.5469L29.0469 16.5469Z"
          fill="#F8F1E3"
        />
      </svg>
    </div>
  )
}
