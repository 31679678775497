import React from 'react'

import './vector1.css'

export default function Vector1() {
  return (
    <div className="vector1-vector1">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 1439 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 1L1440 0.999874" stroke="#F8F1E3" />
      </svg>
    </div>
  )
}
