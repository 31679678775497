import React from 'react'

import './stamp-solid1.css'

export default function StampSolid1() {
  return (
    <div className="stamp-solid1-stamp-solid1">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.6094 20H31.25C36.0859 20 40 23.9141 40 28.75C40 30.3828 38.9531 31.7734 37.5 32.2891V36.25C37.5 38.3203 35.8203 40 33.75 40H6.25C4.17891 40 2.5 38.3203 2.5 36.25V32.2891C1.04375 31.7734 0 30.3828 0 28.75C0 23.9141 3.91719 20 8.75 20H11.3906C13.7266 20 15.625 18.1016 15.625 15.7656C15.625 14.3984 14.9063 13.1641 14.0703 12.0938C13.0859 10.8203 12.5 9.22656 12.5 7.5C12.5 3.35781 15.7891 0 20 0C24.1406 0 27.5 3.35781 27.5 7.5C27.5 9.22656 26.9141 10.8203 25.9297 12.0938C25.0937 13.1641 24.375 14.3984 24.375 15.7656C24.375 18.1016 26.2734 20 28.6094 20ZM32.5 32.5H7.5V35H32.5V32.5Z"
          fill="#F8F1E3"
        />
      </svg>
    </div>
  )
}
