import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createRoot } from 'react-dom/client'

import Main from './pages/main'

export default function App() {
  return (
    <Router>
      <head>
        <title>Stamp Protocol</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      </head>
      <div>
        <Route exact component={Main} path="/" />
      </div>
    </Router>
  )
}

// @ts-expect-error
const root = createRoot(document.getElementById('app'))

root.render(<App />)
